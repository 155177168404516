// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lembaga-desa-js": () => import("./../../../src/pages/lembaga-desa.js" /* webpackChunkName: "component---src-pages-lembaga-desa-js" */),
  "component---src-pages-pemerintah-desa-js": () => import("./../../../src/pages/pemerintah-desa.js" /* webpackChunkName: "component---src-pages-pemerintah-desa-js" */),
  "component---src-pages-potensi-dan-produk-usaha-js": () => import("./../../../src/pages/potensi-dan-produk-usaha.js" /* webpackChunkName: "component---src-pages-potensi-dan-produk-usaha-js" */),
  "component---src-pages-tentang-desa-js": () => import("./../../../src/pages/tentang-desa.js" /* webpackChunkName: "component---src-pages-tentang-desa-js" */),
  "component---src-templates-agenda-js": () => import("./../../../src/templates/agenda.js" /* webpackChunkName: "component---src-templates-agenda-js" */),
  "component---src-templates-artikel-js": () => import("./../../../src/templates/artikel.js" /* webpackChunkName: "component---src-templates-artikel-js" */),
  "component---src-templates-berita-js": () => import("./../../../src/templates/berita.js" /* webpackChunkName: "component---src-templates-berita-js" */),
  "component---src-templates-data-desa-js": () => import("./../../../src/templates/data-desa.js" /* webpackChunkName: "component---src-templates-data-desa-js" */),
  "component---src-templates-galeri-js": () => import("./../../../src/templates/galeri.js" /* webpackChunkName: "component---src-templates-galeri-js" */),
  "component---src-templates-laporan-js": () => import("./../../../src/templates/laporan.js" /* webpackChunkName: "component---src-templates-laporan-js" */),
  "component---src-templates-panduan-layanan-publik-js": () => import("./../../../src/templates/panduan-layanan-publik.js" /* webpackChunkName: "component---src-templates-panduan-layanan-publik-js" */),
  "component---src-templates-pengumuman-js": () => import("./../../../src/templates/pengumuman.js" /* webpackChunkName: "component---src-templates-pengumuman-js" */),
  "component---src-templates-perancangan-dan-penganggaran-js": () => import("./../../../src/templates/perancangan-dan-penganggaran.js" /* webpackChunkName: "component---src-templates-perancangan-dan-penganggaran-js" */),
  "component---src-templates-produk-hukum-js": () => import("./../../../src/templates/produk-hukum.js" /* webpackChunkName: "component---src-templates-produk-hukum-js" */),
  "component---src-templates-template-announcement-js": () => import("./../../../src/templates/template-announcement.js" /* webpackChunkName: "component---src-templates-template-announcement-js" */),
  "component---src-templates-template-news-js": () => import("./../../../src/templates/template-news.js" /* webpackChunkName: "component---src-templates-template-news-js" */)
}

